import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../App'
import { MessageService } from '../services/MessageService'
import { UserService } from '../services/UserServive'
import Column from './ui/Column'

export default function MessageBoard() {

    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState('')
    const [messageService] = useState(new MessageService())
    const [activeUsers, setActiveUsers] = useState([])

    const [user] = useContext(UserContext)
    
    useEffect(() => {
        if (user) {
            messageService.getAllMessages(user.token).then(setMessages)
        }
        // the first function passed in will be called when new messages are received
        // the second function passed in will be called when new acrive users are received
        messageService.connectToWebSocket(
            user, 
            message => setMessages(messages => [message, ...messages]),
            setActiveUsers)
        return () => messageService.closeWebSocket()
    }, [messageService, user])

    const handleSubmit = event => {
        event.preventDefault()
        if (newMessage) {
            messageService.sendMessageToWebSocket(user, newMessage)
            setNewMessage('')
        }
    }

    return (
        <Column heading="Message Board">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <div className="text-start pt-2 pb-2 ps-3 pe-3" style={{height: '12rem', overflow: 'scroll', backgroundColor: 'white', borderRadius: '0.25rem'}}>
                        {messages.map(({id, dateTime, tipster, content}) => {
                            const formattedDateTime = new Date(dateTime).toLocaleString().replace(/:\d{2}$/, '')
                            return (
                                <div key={id}>
                                    <strong>{tipster.username} &nbsp;</strong>
                                    <small style={{color: 'grey'}}>{formattedDateTime}</small>
                                    <p>{content}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="input-group">
                    <input 
                        value={newMessage}
                        onChange={e => setNewMessage(e.target.value)} 
                        placeholder="Your message to all" 
                        className="form-control" />
                    <button className="btn" style={{boxShadow: 'none'}}>Post</button>
                </div>
                <small className="d-block text-start ps-1 pt-1">Online now: {activeUsers.join(', ')}</small>
            </form>
        </Column>
    )
}
