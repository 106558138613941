import React from 'react';
import { useEffect } from 'react'; 
const Modal = ({text, onClose}) => {
    const buttonRef = React.createRef();
    useEffect(() => {
        buttonRef.current.focus();
    }, []);
    return (
        <div className="modal">
            <div>
                <p className="mt-3">{text}</p>
                <button ref={buttonRef} onClick={onClose} className="btn">OK</button>
            </div>
        </div>
    );
}

export default Modal;