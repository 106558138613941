const TextBox = ({label, type='text', placeholder='', value, setValue}) => {
    // trim (remove whitespace at each end), replace all whitespace chars and round brackets, and lower case
    const id = label.trim().replace(/[\s()]/g).toLowerCase();
    return (
        <div className="mb-3">
            <label htmlFor={id} className="form-label">{label}</label>
            <input 
                type={type} 
                id={id} 
                placeholder={placeholder}
                value={value} 
                onChange={e => setValue(e.target.value)} 
                className="form-control text-center" />
        </div>
    );
}

export default TextBox;