import { Link } from "react-router-dom";

const Navbar = ({menuItems}) => {
    return (
        <nav className="navbar navbar-expand-sm navbar-light mb-2">
            <div className="container-fluid justify-content-center">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menuItems" aria-controls="menuItems" aria-expanded="false" aria-label="Toggle navigation" style={{boxShadow: 'none'}}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center mt-2" id="menuItems">
                    <ul className="navbar-nav text-center">
                        {menuItems.map(({predicate=() => true, path, value, subItems=[]}, index) => {
                            if (!subItems.length) {
                                return (
                                    <div key={index}>
                                        {predicate() && 
                                        <li className="nav-item">
                                            <Link to={path} className="nav-link">{value}</Link>
                                        </li>
                                        }
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={index}>
                                        {predicate() &&
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id={`${value}Dropdown`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {value}
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby={`${value}Dropdown`}>
                                                {subItems.map(({path, value}, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Link to={path} className="dropdown-item">{value}</Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </li>
                                        }
                                    </div>
                                )
                            }
                        })}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;