import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { UserService } from "../services/UserServive"
import Button from "./ui/Button"
import Column from "./ui/Column"
import Modal from "./ui/Modal"
import TextBox from "./ui/TextBox"

const ResetCredentialsForm = () => {

    const [email, setEmail] = useState('')
    const [usernames, setUsernames] = useState([])
    const [selectedUsername, setSelectedUsername] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [haveValidCode, setHaveValidCode] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    const [modalMsg, setModalMsg] = useState('')
    
    const [searchParams] = useSearchParams()

    useEffect(() => {
        if (searchParams.has('code') && searchParams.has('usernames')) {
            const code = searchParams.get('code')
            const usernameArray = searchParams.get('usernames').split(',')
            // I'm deliberately not handling the error case; if the code is invalid then do nothing
            UserService.validateResetCode(code).then(() => {
                setHaveValidCode(true)
                setUsernames(usernameArray)
                setSelectedUsername(usernameArray[0])
            })
        } else {
            setHaveValidCode(false)
            setUsernames([])
        }
    }, [searchParams])

    const handleSendResetEmail = () => {
        setInProgress(true)
        UserService.initiateCredentialsReset(email)
            .then(() => setModalMsg('Done! Now check your emails'))
            .catch(error => setModalMsg(error.message))
            .finally(() => setInProgress(false))
    }

    const handleSubmit = () => {
        setInProgress(true)
        UserService.completeCredentialsReset(selectedUsername, password1, password2)
            .then(() => {
                setModalMsg('Done! Login with your new password')
                setPassword1('')
                setPassword2('')
            })
            .catch(error => setModalMsg(error.message))
            .finally(() => setInProgress(false))
    }

    return (
        <Column heading={!haveValidCode ? "Reset Credentials (Step 1)" : "Reset Credentials (Step 2)"}>
            {!haveValidCode && (
                <form style={{cursor: inProgress ? 'progress' : 'inherit'}}>
                    <TextBox label="* Email" value={email} setValue={setEmail} />
                    <Button label="Send reset email" disabled={inProgress} onClick={handleSendResetEmail} />
                </form>
            )}
            {haveValidCode && (
                <form>
                    {selectedUsername && <p>Resetting password for <strong>{selectedUsername}</strong></p>}
                    {usernames.length > 1 && (
                        <div className="mb-3">
                            <label className="form-label">Username</label>
                            <select 
                                value={selectedUsername} 
                                onChange={e => setSelectedUsername(e.target.value)}
                                className="form-select">
                                    {usernames.map(u => <option value={u} className="text-center">{u}</option>)}
                            </select>
                        </div>
                    )}
                    <TextBox 
                        label="* New password (8+ characters)" 
                        type="password" 
                        value={password1}
                        setValue={setPassword1} />
                    <TextBox 
                        label="* New password (again)" 
                        type="password" 
                        value={password2}
                        setValue={setPassword2} />
                    <Button label="Submit" disabled={inProgress} onClick={handleSubmit} />
                </form>
            )}
            {modalMsg && <Modal text={modalMsg} onClose={() => setModalMsg('')} />}
        </Column>
    )
}

export default ResetCredentialsForm