import { useContext, useEffect } from "react"
import { Navigate } from "react-router-dom";
import { UserContext } from "../App"

const Logout = () => {
    const [user, setUser] = useContext(UserContext);
    useEffect(() => setUser(null), []);
    return <Navigate to="/login" />;
}

export default Logout;