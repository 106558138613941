import ResetCredentialsForm from "./ResetCredentialsForm"
import OneColumnPage from "./ui/OneColumnPage"

const ResetCredentialsPage = () => {
    return (
        <OneColumnPage>
            <ResetCredentialsForm />
        </OneColumnPage>
    );
}

export default ResetCredentialsPage