import React from 'react'
import Leaderboard from './Leaderboard'
import MessageBoard from './MessageBoard'

export default function Boards() {

    return (
        <>
            <MessageBoard />
            <Leaderboard />
        </>
    )
}
