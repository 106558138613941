const emailIsInvalid = email => {
    return !email.match(/^\S+@\S+\.\S+$/);
}

const usernameIsInvalid = username => {
    return !username.match(/^\S{3,}$/);
}

const passwordIsInvalid = password => {
    return !password.match(/^\S{8,}$/);
}

const isBlank = value => {
    return !value.match(/^\S+$/);
}

export { emailIsInvalid, usernameIsInvalid, passwordIsInvalid, isBlank };