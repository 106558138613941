const Column = ({heading, children}) => {
    return (
        <div className="mb-4">
            <h3>{heading}</h3>
            <div className="border border-1 rounded p-4">
                {children}
            </div>
        </div>
    );
}

export default Column;