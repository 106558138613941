import { useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { isBlank } from "../utils/validators";
import { UserContext } from "../App";
import Modal from "./ui/Modal";
import TextBox from "./ui/TextBox";
import Column from "./ui/Column";
import { UserService } from "../services/UserServive";

const LoginForm = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [user, setUser] = useContext(UserContext);

    const handleLogin = event => {
        event.preventDefault();
        setInProgress(true);
        if (isBlank(username) || isBlank(password)) {
            setModalMsg('Both fields are required');
            setInProgress(false);
        } else {
            UserService.login({username, password}).then(user => {
                setUser(user)
                setInProgress(false)
            }).catch(error => {
                setModalMsg(error.message)
                setInProgress(false)
            });
        }
    }

    return (
        <>
            {user && <Navigate to="/" />}
            <Column heading="Login">
                <form onSubmit={handleLogin} style={{cursor: inProgress ? 'progress' : 'inherit'}}>
                    <TextBox label="* Username" value={username} setValue={setUsername} />
                    <TextBox label="* Password" type="password" value={password} setValue={setPassword} />
                    <div className="mb-3">
                        <small><Link to="/resetcredentials" className="nav-link text-body">I forgot my username and/or password</Link></small>
                    </div>
                    <button disabled={inProgress} className="btn">Login</button>
                </form>
                {modalMsg && <Modal text={modalMsg} onClose={() => setModalMsg('')} />}
            </Column>
        </>
    );
}

export default LoginForm;