import { useContext } from 'react';
import { UserContext } from '../App';

const Header = () => {
    const [user] = useContext(UserContext);
    return (
        <h1 className="display-4 mt-4 text-center">
            {user && `${user.username}, the `} SUPER FOOTY TIPPER
        </h1>
    );
}

export default Header;