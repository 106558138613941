import axios from "axios"

export const LeaderboardService = {

    getCurrentLeaderboard: async token => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/leaderboard`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            return response.data
        } catch (error) {
            console.dir(error)
            if (error.response.status === 401) {
                throw new Error('Time\'s up! Logout and login again')
            } else {
                throw new Error('Oops: something\'s gone wrong')
            }
        }
    }
}