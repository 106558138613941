import ADEL from "../images/ADEL.svg";
import BRIS from "../images/BRIS.svg";
import CARL from "../images/CARL.svg";
import COLL from "../images/COLL.svg";
import ESS from "../images/ESS.svg";
import FRE from "../images/FRE.svg";
import GC from "../images/GC.svg";
import GEE from "../images/GEE.svg";
import GWS from "../images/GWS.svg";
import HAW from "../images/HAW.svg";
import MEL from "../images/MEL.svg";
import NM from "../images/NM.svg";
import PA from "../images/PA.svg";
import RICH from "../images/RICH.svg";
import STK from "../images/STK.svg";
import SYD from "../images/SYD.svg";
import WC from "../images/WC.svg";
import WB from "../images/WB.svg";

export const svgFromTeamAbbr = teamAbbr => {
    switch (teamAbbr) {
        case 'ADEL':
            return ADEL;
        case 'BRIS':
            return BRIS;
        case 'CARL':
            return CARL;
        case 'COLL':
            return COLL;
        case 'ESS':
            return ESS;
        case 'FRE':
            return FRE;
        case 'GC':
            return GC;
        case 'GEE':
            return GEE;
        case 'GWS':
            return GWS;
        case 'HAW':
            return HAW;
        case 'MEL':
            return MEL;
        case 'NM':
            return NM;
        case 'PA':
            return PA;
        case 'RICH':
            return RICH;
        case 'STK':
            return STK;
        case 'SYD':
            return SYD;
        case 'WC':
            return WC;
        case 'WB':
            return WB;
    }
}