const OneColumnPage = ({children}) => {
    return (
        <div className="container-fluid mb-4">
            <div className="row justify-content-center">
                <div className="col-sm-8 col-md-6 col-lg-4 text-center">{children}</div>
            </div> 
        </div>
    );
}

export default OneColumnPage;