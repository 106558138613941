import { useContext, useEffect, useState } from "react";
import { Actions, AppStateContext, UserContext } from "../App";
import Column from "./ui/Column";
import { svgFromTeamAbbr } from "../utils/svg";
import cross from "../images/cross.svg";
import tick from "../images/tick.svg";
import Modal from "./ui/Modal";
import { GameService } from "../services/GameService";
import { TipService } from "../services/TipService";

const Games = () => {

    const [games, setGames] = useState([]);
    const [existingTips, setExistingTips] = useState([]);
    const [newTips, setNewTips] = useState([]);
    const [modalMsg, setModalMsg] = useState('');
    const [inProgress, setInProgress] = useState(false);

    const [user] = useContext(UserContext);
    const [_, dispatch] = useContext(AppStateContext);

    useEffect(() => {
        if (user) {
            GameService.getCurrentGames(user.token)
                .then(setGames)
                .catch(error => setModalMsg(error.message));
        }
    }, [user]);

    useEffect(() => {
        setExistingTips(games
            .flatMap(game => game.tips)
            .filter(tip => tip.tipsterId === user.id)
            .map(tip => ({...tip, team: tip.team.dbName})));
    }, [games, user]);

    const handleSelectTeam = (gameId, tipsterId, team) => {
        // remove the existing tip if it exists then add the new one
        setNewTips(newTips.filter(tip => tip.gameId !== gameId).concat({gameId, tipsterId, team: team.dbName}));
    }

    const handleSubmitTips = () => {
        setInProgress(true);
        TipService.addNewTips(newTips, user.token)
            .then(() => {
                setExistingTips(existingTips.concat(newTips));
                setNewTips([]);
                setModalMsg('Your tips are in!');
                setInProgress(false);
            })
            .catch(error => setModalMsg(error.message));
    }

    return (
        <Column heading={games.length > 0 ? `Round ${games[0].round} Games` : 'Games'}>
            <form>
                {games.sort((g1, g2) => new Date(g1.dateTime) - new Date(g2.dateTime)).map((game, index) => {
                    const {id: gameId, homeTeam, awayTeam, complete, winner} = game;
                    const usersTip = existingTips.concat(newTips).find(tip => tip.gameId === gameId);
                    return (
                        <div id="game" key={gameId} 
                            onMouseOver={() => dispatch({type: Actions.MOUSE_OVER_GAME, payload: game})}
                            onMouseOut={() => dispatch({type: Actions.MOUSE_OVER_GAME, payload: null})}
                            className="d-flex justify-content-around"  
                            style={{height: index === games.length - 1 ? '5rem' : '6rem'}}>
                            <button 
                                type="button"
                                disabled={complete || existingTips.find(tip => tip.gameId === gameId)} 
                                className="btn" 
                                style={{backgroundColor: usersTip && usersTip.team === homeTeam.dbName ? '#decf5c' : 'white'}}
                                onClick={() => handleSelectTeam(gameId, user.id, homeTeam)}>
                                <img src={svgFromTeamAbbr(homeTeam.abbr)} alt={homeTeam.name} title={homeTeam.name} />
                                {usersTip && usersTip.team === homeTeam.dbName && winner.name !== 'None' &&
                                    <img src={homeTeam.name === winner.name ? tick : cross} className="tickOrCross" />
                                }
                            </button>
                            <button 
                                type="button"
                                disabled={complete || existingTips.find(tip => tip.gameId === gameId)} 
                                className="btn"
                                style={{backgroundColor: usersTip && usersTip.team === awayTeam.dbName ? '#decf5c' : 'white'}}
                                onClick={() => handleSelectTeam(gameId, user.id, awayTeam)}>
                                <img src={svgFromTeamAbbr(awayTeam.abbr)} alt={awayTeam.name} title={awayTeam.name} />
                                {usersTip && usersTip.team === awayTeam.dbName && winner.name !== 'None' &&
                                    <img className="tickOrCross" src={awayTeam.name === winner.name ? tick : cross} />
                                }
                            </button>
                        </div>  
                    );
                })}
                {newTips.length > 0 && <button type="button" disabled={inProgress} onClick={handleSubmitTips} className="btn mt-4">Submit my tips</button>}
            </form>
            {modalMsg && <Modal text={modalMsg} onClose={() => setModalMsg('')} />}
        </Column>
    );
}

export default Games;