import { useState } from 'react';
import Button from './ui/Button';
import Modal from './ui/Modal';
import TextBox from "./ui/TextBox";
import Column from './ui/Column';
import { UserService } from '../services/UserServive';

const RegistrationForm = () => {

    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [modalMsg, setModalMsg] = useState('');

    const handleRegister = () => {
        setInProgress(true);
        UserService.registerNewUser(email, username, password1, password2)
            .then(() => {
                setModalMsg('Done! Now check your emails');
                setEmail('');
                setUsername('');
                setPassword1('');
                setPassword2('');
                setInProgress(false);
            })
            .catch(error => {
                setModalMsg(error.message);
                setInProgress(false);
            });
    }

    return (
        <Column heading="Register">
            <form style={{cursor: inProgress ? 'progress' : 'inherit'}}>
                <TextBox label="* Email" value={email} setValue={setEmail} />
                <TextBox label="* Username (3+ characters)" value={username } setValue={setUsername} />
                <TextBox 
                    label="* Password (8+ characters)" 
                    type="password" 
                    value={password1}
                    setValue={setPassword1} />
                <TextBox 
                    label="* Password (again)" 
                    type="password" 
                    value={password2}
                    setValue={setPassword2} />
                <Button label="Register" disabled={inProgress} onClick={handleRegister} />
            </form>
            {modalMsg && <Modal text={modalMsg} onClose={() => setModalMsg('')} />}
        </Column>
    );
}

export default RegistrationForm;