import { useContext } from "react";
import { UserContext } from "../App";
import Navbar from "./ui/Navbar";

const MainNavbar = () => {

    const [user] = useContext(UserContext);

    return (
        <Navbar menuItems={[
            {predicate: () => user && user.role === 'ADMIN', path: '/', value: 'Home'},
            {predicate: () => !user, path: '/register', value: 'Register'},
            {predicate: () => !user, path: '/login', value: 'Login'},
            {predicate: () => user && user.role === 'ADMIN', path: '/admin', value: 'Admin', subItems: [
                {path: '/admin/newgame', value: 'New game'},
                {path: '/admin/newresult', value: 'New result'}
            ]},
            {predicate: () => user, path: '/logout', value: 'Logout'},
        ]} />
    );
}

export default MainNavbar;