import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../App";
import Boards from "./Boards";
import Games from "./Games";
import Ladder from "./Ladder";
import ThreeColumnPage from "./ui/ThreeColumnPage";

const HomePage = () => {

    const [user] = useContext(UserContext);

    return (
        <>
            {!user && <Navigate to="/login" />}
            <ThreeColumnPage>
                <Ladder />
                <Games />
                <Boards />
            </ThreeColumnPage>
        </>
    );
}

export default HomePage;