import axios from 'axios'

export const GameService = {

    addNewGame: async (game, token) => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/games`,
                {...game, dateTime: game.dateTime + 'Z'}, 
                {
                    headers: { 
                        Authorization: `Bearer ${token}`
                    }
                }
            )
        } catch (error) {
            console.dir(error)
            if (error.response.status === 401) {
                throw new Error('Time\'s up! Logout and login again')
            } else {
                throw new Error('You\'ve one or more invalid fields')
            }
        }
    },

    updateGame: async (game, homeTeamScore, awayTeamScore, token) => {
        // don't update the associated tips
        delete game.tips
        const updatedGame = {
            ...game, 
            homeTeamScore, 
            awayTeamScore
        }
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/games/${updatedGame.id}`, 
                {
                    ...updatedGame,
                    // dbName is the enum value used server-side
                    homeTeam: game.homeTeam.dbName,
                    awayTeam: game.awayTeam.dbName
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            return updatedGame
        } catch (error) {
            console.dir(error)
            if (error.response.status === 401) {
                throw new Error('Time\'s up! Logout and login again')
            } else {
                throw new Error('Oops: something\'s gone wrong')
            }
        }
    },

    searchGames: async (season, round, token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/games?season=${season}&round=${round}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (response.data.length) {
                return response.data
            } else {
                return []
            }
        } catch (error) {
            console.dir(error)
            if (error.response.status === 401) {
                throw new Error('Time\'s up! Logout and login again')
            } else {
                throw new Error('Oops: something\'s gone wrong')
            }
        }
    },

    getCurrentGames: async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/games`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (response.data.length) {
                return response.data
            } else {
                return []
            }
        } catch (error) {
            console.dir(error)
            if (error.response.status === 401) {
                throw new Error('Time\'s up! Logout and login again')
            } else {
                throw new Error('Oops: something\'s gone wrong')
            }
        }
    }
}