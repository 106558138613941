/*
 * The first child will NOT be rendered on small screens (portait tablet and landscape phone)
 * Assumed layout: Ladder, Games, Leaderboard
 * If more than the three children are given then the excess will be ignored
 */
const ThreeColumnPage = ({children}) => {
    return (
        <div className="container-fluid mb-4">
            <div className="row justify-content-center">
                <div className="d-none d-lg-block col-lg-4 text-center">{children[0]}</div>
                <div className="col-sm-6 col-lg-4 text-center">{children[1]}</div>
                <div className="col-sm-6 col-lg-4 text-center">{children[2]}</div>
                <div className="d-sm-none text-center">{children[0]}</div>
            </div> 
        </div>
    );
}

export default ThreeColumnPage;