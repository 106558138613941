import RegistrationForm from "./RegistrationForm";
import OneColumnPage from "./ui/OneColumnPage";

const RegisterPage = () => {
    return (
        <OneColumnPage>
            <RegistrationForm />
        </OneColumnPage>
    );
}

export default RegisterPage;