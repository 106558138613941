import axios from 'axios'
import jwt_decode from "jwt-decode"
import { emailIsInvalid, passwordIsInvalid, usernameIsInvalid } from '../utils/validators';

export const UserService = {

    registerNewUser: async (email, username, password1, password2) => {
        const invalidFields = [];
        let formIsValid = true;
        if (emailIsInvalid(email)) {
            invalidFields.push('email');
        }
        if (usernameIsInvalid(username)) {
            invalidFields.push('username');
        }
        if (passwordIsInvalid(password1)) {
            invalidFields.push('password');
        }
        if (invalidFields.length) {
            formIsValid = false;
            throw new Error(`Invalid fields: ${invalidFields.join(', ')}`);
        }
        if (password1 !== password2) {
            formIsValid = false;
            throw new Error('The passwords do not match');
        }
        if (formIsValid) {
            try {
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/tipsters`, {
                    email, username, password: password1
                });
            } catch (error) {
                console.dir(error);
                throw new Error('Oops: something\'s gone wrong');
            }
        }
    },
    
    login: async ({username, password}) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                headers: {
                    // btoa is deprecated in Node but not in the browser
                    // I'm prefixing it with window so that VSCode doesn't show a deprecation warning
                    Authorization: `Basic ${window.btoa(username + ':' + password)}`
                }
            })
            const token = response.data
            const decodedToken = jwt_decode(token)
            return {
                id: decodedToken.uid,
                username: decodedToken.una,
                role: decodedToken.uro,
                token: token
            }
        } catch (error) {
            console.dir(error)
            if (error.message === 'Network Error') {
                throw new Error('Oops: the server can\'t be contacted')
            } else if (error.response.status === 401) {
                throw new Error('Bad username and/or password')
            } else {
                throw new Error(`Oops: something's gone wrong`)
            }
        }
    },

    initiateCredentialsReset: async email => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/tipsters/initcredsreset`, email, {
                headers: {
                    'Content-Type': 'text/plain'
                }
            });
        } catch (error) {
            console.dir(error);
            throw new Error('Oops: something\'s gone wrong');
        }
    },

    validateResetCode: async code => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/tipsters/validatecredsresetcode`, code, {
                headers: {
                    'Content-Type': 'text/plain'
                }
            });
        } catch (error) {
            console.dir(error);
            throw new Error('Oops: something\'s gone wrong');
        }
    },

    completeCredentialsReset: async (username, password1, password2) => {
        if (passwordIsInvalid(password1)) {
            throw new Error('The new password is invalid');
        } else if (password1 !== password2) {
            throw new Error('The passwords do not match');
        } else {
            try {
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/tipsters/completecredsreset/${username}`, password1, {
                    headers: {
                        'Content-Type': 'text/plain'
                    }
                });
            } catch (error) {
                console.dir(error);
                throw new Error('Oops: something\'s gone wrong');
            }
        }
    }
}