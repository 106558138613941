import axios from "axios"

export class MessageService {

    async getAllMessages(token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/messages`, {
            headers: { 
                Authorization: `Bearer ${token}`
            }
        })
        const messages = response.data
        return messages.reverse()
    }
    
    connectToWebSocket(user, onMessage, onActiveUsers) {
        this.webSocket = new WebSocket(`${process.env.REACT_APP_BACKEND_URL.replace('http', 'ws')}/ws-messages`)
        this.webSocket.onopen = () => {
            this.webSocket.send(JSON.stringify({
                tipster: {
                    id: user.id,
                    username: user.username
                },
                content: '__init__'
            }))
        }
        this.webSocket.onmessage = event => {
            const parsedData = JSON.parse(event.data)
            if (Array.isArray(parsedData)) {
                onActiveUsers(parsedData)
            } else {
                onMessage(parsedData)
            }
        }
    }

    sendMessageToWebSocket(user, message) {
        this.webSocket.send(JSON.stringify({
            dateTime: new Date().toJSON(),
            tipster: {
                id: user.id,
                username: user.username
            },
            content: message
        }))
    }

    closeWebSocket() {
        if (this.webSocket && this.webSocket.OPEN) {
            this.webSocket.close()
        }
    }
}