import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './App.css';
import React, { useReducer } from 'react';
import { useState } from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import RegisterPage from './components/RegisterPage';
import ResetCredentialsPage from './components/ResetCredentialsPage';
import LoginPage from './components/LoginPage';
import Logout from './components/Logout';
import AdminPage from './components/AdminPage';
import MainNavbar from './components/MainNavbar';
import NewGame from './components/NewGame';
import NewResult from './components/NewResult';

// START useReducer config
const appState = {
  ladderOn: false,
  mouseOverGame: null
}

export const Actions = {
  LADDER_ON: 'LADDER_ON',
  MESSAGE_BOARD_ON: 'MESSAGE_BOARD_ON',
  MOUSE_OVER_GAME: 'MOUSE_OVER_GAME'
}

const reducer = (state=appState, action) => {
  const {type, payload} = action;
  switch (type) {
    case Actions.LADDER_ON:
      return {...state, ladderOn: true};
    case Actions.MESSAGE_BOARD_ON:
        return {...state, ladderOn: false};
    case Actions.MOUSE_OVER_GAME:
      return {...state, mouseOverGame: payload};
    default:
      return state;
  }
}
// END useReducer config

export const UserContext = React.createContext();
export const AppStateContext = React.createContext();

const App = () => {
  const [user, setUser] = useState(null);
  const [state, dispatch] = useReducer(reducer, appState);
  return (
    <UserContext.Provider value={[user, setUser]}>
      <AppStateContext.Provider value={[state, dispatch]}>
        <BrowserRouter>
          <div id="mainDiv" className="container-fluid">
            <Header />
            <MainNavbar />
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/register" element={<RegisterPage />} />
              <Route exact path="/resetcredentials" element={<ResetCredentialsPage />} />
              <Route exact path="/login" element={<LoginPage />} />
              <Route path="/admin" element={<AdminPage />}>
                <Route path="newgame" element={<NewGame />} />
                <Route path="newresult" element={<NewResult />} />
              </Route>
              <Route exact path="/logout" element={<Logout />} />
            </Routes>
          </div>
        </BrowserRouter>
      </AppStateContext.Provider>
    </UserContext.Provider>
  );
}

export default App;