import { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import Column from "./ui/Column";
import Button from "./ui/Button";
import TextBox from "./ui/TextBox";
import Modal from "./ui/Modal";
import { GameService } from "../services/GameService";

const NewResult = () => {

    const [season, setSeason] = useState(new Date().getFullYear());
    const [round, setRound] = useState(1);
    const [games, setGames] = useState([]);
    const [selectedGameId, setSelectedGameId] = useState('');
    const [homeTeamScore, setHomeTeamScore] = useState('');
    const [awayTeamScore, setAwayTeamScore] = useState('');
    const [modalMsg, setModalMsg] = useState('');
    const [user] = useContext(UserContext);

    /*
     * When the user searches by season and round (assuming there are results)
     * then games.length will be > 0 but the user will have yet to select a game,
     * so selectedGameId will be ''. In that case we should select the first game
     * and set selectedGameId accordingly. This will ensure that if the user has
     * previously saved results for this game then its scores will be rendered.
     */
    useEffect(() => {
        if (games.length && !selectedGameId) {
            const firstGame = games[0];
            setSelectedGameId(firstGame.id);
        } else if (!games.length) {
            setSelectedGameId('');
        }
    }, [games]);

    /*
     * When the user selects a game id we should fetch the assoc. game 
     * and set both homeTeamScore and awayTeamScore accordingly.
     * This is only necessary when results for the game have been input previously.
     */ 
    useEffect(() => {
        if (selectedGameId) {
            const selectedGame = games.find(game => game.id == selectedGameId);
            setHomeTeamScore(selectedGame.homeTeamScore || '');
            setAwayTeamScore(selectedGame.awayTeamScore || '');
        }
    }, [selectedGameId]);

    const handleSearch = () => {
        GameService.searchGames(season, round, user.token)
            .then(setGames)
            .catch(error => setModalMsg(error.message));
    }

    const handleSave = () => {
        // note the == because selectedGameId is NOT a number
        const selectedGame = games.find(game => game.id == selectedGameId);
        GameService.updateGame(selectedGame, +homeTeamScore, +awayTeamScore, user.token)
            .then(updatedGame => {
                setGames(games.map(game => {
                    if (game.id === updatedGame.id) {
                        return updatedGame;
                    }
                    return game;
                }));
                setModalMsg('Done!');
            })
            .catch(error => setModalMsg(error.message));
    }

    return (
        <Column heading="New Result">
            <form>
                <TextBox label="* Season" value={season} setValue={setSeason} />
                <TextBox label="* Round" type="number" value={round} setValue={setRound} />
                <Button label="Search" onClick={handleSearch} />
            </form>
            {games.length > 0 &&
                <div className="mt-3 mb-3">
                    <label htmlFor="game" className="form-label">Select game</label>
                    <select 
                        value={selectedGameId} 
                        onChange={e => setSelectedGameId(e.target.value)}
                        className="form-control text-center">
                        {games
                            .sort((g1, g2) => new Date(g1.dateTime) - new Date(g2.dateTime))
                            .map((game, index) => {
                                return <option key={index} value={game.id}>{game.homeTeam.name} vs {game.awayTeam.name}</option>;
                            })
                        }
                    </select>
                </div>
            }
            {selectedGameId && 
                <form>
                    <TextBox label="* Home team score" value={homeTeamScore} setValue={setHomeTeamScore} />
                    <TextBox label="* Away team score" value={awayTeamScore} setValue={setAwayTeamScore} />
                    <Button label="Save" onClick={handleSave} />
                </form>
            }
            {modalMsg && <Modal text={modalMsg} onClose={() => setModalMsg('')} />}
        </Column>
    );
}

export default NewResult;