import axios from 'axios'

export const TipService = {

    addNewTips: async (newTips, token) => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/tips/batch`, newTips, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        } catch (error) {
            console.dir(error)
            if (error.response.status === 401) {
                throw new Error('Time\'s up! Logout and login again')
            } else {
                throw new Error('Oops: something\'s gone wrong')
            }
        }
    }
}