import { useContext, useState } from "react";
import { UserContext } from "../App";
import { GameService } from "../services/GameService";
import Button from "./ui/Button";
import Column from "./ui/Column";
import Modal from "./ui/Modal";
import TextBox from "./ui/TextBox";

const teamDropDownOptions = [
    <option key="0" value="ADELAIDE_CROWS">Adelaide</option>,
    <option key="1" value="BRISBANE_LIONS">Brisbane</option>,
    <option key="3" value="CARLTON_BLUES">Carlton</option>,
    <option key="4" value="COLLINGWOOD_MAGPIES">Collingwood</option>,
    <option key="5" value="ESSENDON_BOMBERS">Essendon</option>,
    <option key="6" value="FREMANTLE_DOCKERS">Fremantle</option>,
    <option key="7" value="GEELONG_CATS">Geelong</option>,
    <option key="8" value="GOLD_COAST_SUNS">Gold Coast</option>,
    <option key="9" value="GREATER_WESTERN_SYDNEY_GIANTS">Greater Western Sydney</option>,
    <option key="10" value="HAWTHORN_HAWKS">Hawthorn</option>,
    <option key="11" value="MELBOURNE_DEMONS">Melbourne</option>,
    <option key="12" value="NORTH_MELBOURNE_KANGAROOS">North Melbourne</option>,
    <option key="13" value="PORT_ADELAIDE_POWER">Port Adelaide</option>,
    <option key="14" value="RICHMOND_TIGERS">Richmond</option>,
    <option key="15" value="ST_KILDA_SAINTS">St Kilda</option>,
    <option key="16" value="SYDNEY_SWANS">Sydney</option>,
    <option key="17" value="WEST_COAST_EAGLES">West Coast</option>,
    <option key="18" value="WESTERN_BULLDOGS">Western Bulldogs</option>
];

const NewGame = () => { 

    const [season, setSeason] = useState(new Date().getFullYear());
    const [round, setRound] = useState(1);
    const [dateTime, setDateTime] = useState('');
    const [homeTeam, setHomeTeam] = useState('ADELAIDE_CROWS');
    const [awayTeam, setAwayTeam] = useState('BRISBANE_LIONS');
    const [modalMsg, setModalMsg] = useState('');
    const [user] = useContext(UserContext);

    const handleNewGame = () => {
        GameService.addNewGame({season, round, dateTime, homeTeam, awayTeam}, user.token)
            .then(() => setModalMsg('Done!'))
            .catch(error => setModalMsg(error.message));
    }

    return (
        <Column heading="New Game">
            <form>
                <TextBox label="* Season" value={season} setValue={setSeason} />
                <TextBox label="* Round" type="number" value={round} setValue={setRound} />
                <TextBox 
                    label="* Date and time (GMT)" 
                    type="datetime-local"
                    value={dateTime}
                    setValue={setDateTime} />
                <div className="mb-3">
                    <label htmlFor="homeTeam" className="form-label">Home team</label>
                    <select 
                        id="homeTeam"
                        value={homeTeam} 
                        onChange={e => setHomeTeam(e.target.value)} 
                        className="form-control text-center">
                        {teamDropDownOptions}   
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="awayTeam" className="form-label">Away team</label>
                    <select 
                        id="awayTeam"
                        value={awayTeam} 
                        onChange={e => setAwayTeam(e.target.value)} 
                        className="form-control text-center">
                        {teamDropDownOptions}   
                    </select>
                </div>
                <Button label="Save" onClick={handleNewGame} />
            </form>
            {modalMsg && <Modal text={modalMsg} onClose={() => setModalMsg('')} />}
        </Column>
    )
}

export default NewGame;