import LoginForm from "./LoginForm";
import OneColumnPage from "./ui/OneColumnPage";

const LoginPage = () => {
    return (
        <OneColumnPage>
            <LoginForm />
        </OneColumnPage>
    );
}

export default LoginPage;