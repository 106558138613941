import { useContext, useEffect, useState } from "react";
import { AppStateContext, UserContext } from "../App";
import { LadderService } from "../services/LadderService";
import Column from "./ui/Column";

const Ladder = () => {

    const [ladder, setLadder] = useState([]);

    const [user] = useContext(UserContext);
    const [state, _] = useContext(AppStateContext);

    useEffect(() => {
        if (user) {
            LadderService.getCurrentLadder(user.token).then(setLadder);
        }
    }, [user]);

    const getBackgroundColor = team => {
        if (state.mouseOverGame) {
            const homeTeamAbbr = state.mouseOverGame.homeTeam.abbr;
            const awayTeamAbbr = state.mouseOverGame.awayTeam.abbr;
            if (team.abbr === homeTeamAbbr || team.abbr === awayTeamAbbr) {
                return {
                    backgroundColor: '#decf5c'
                };
            } else {
                return {
                    backgroundColor: 'inherit'
                };
            }
        }
    }

    return (
        <Column heading="Ladder">
            <table id="ladder" className="table table-sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Team</th>
                        <th>Plyd</th>
                        <th>Pts</th>
                        <th>%</th>
                    </tr>
                </thead>
                <tbody>
                    {ladder.map(({team, played, points, percentage}, index) => {
                        return (
                            <tr key={team.abbr} style={getBackgroundColor(team)}>
                                <td>{index + 1}</td>
                                <td>{team.abbr}</td>
                                <td>{played}</td>
                                <td>{points}</td>
                                <td>{percentage}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Column>
    );
}

export default Ladder;