import { useContext } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { UserContext } from "../App";
import OneColumnPage from "./ui/OneColumnPage";

const AdminPage = () => {
    const [user] = useContext(UserContext);
    return (
        <>
            {!user && <Navigate to="/login" />}
            <OneColumnPage>
                <Outlet />
            </OneColumnPage>
        </>
    );
}

export default AdminPage;