import { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import { LeaderboardService } from "../services/LeaderboardService";
import Column from "./ui/Column";

const Leaderboard = () => {

    const [leaderboard, setLeaderboard] = useState([]); 

    const [user] = useContext(UserContext);

    useEffect(() => {
        if (user) {
            LeaderboardService.getCurrentLeaderboard(user.token).then(setLeaderboard);
        }
    }, [user]);

    return (
        <Column heading="Leaderboard">
            <table id="leaderboard" className="table table-sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Tipster</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {leaderboard.map(({tipsterName, totalTips}, index) => {
                        return (
                            <tr key={tipsterName} style={{backgroundColor: tipsterName === user.username ? '#decf5c' : 'inherit'}}>
                                <td>{index + 1}</td>
                                <td>{tipsterName}</td>
                                <td>{totalTips}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>    
        </Column>
    );
}

export default Leaderboard;